<template>
    <div>
        <div class="img-home" id="img-home">
            <div>
                <v-row >
                    <v-col cols="12" sm="9" lg="10" class="text-home" >
                        <h1 class="big-title">Le suivi de la santé dans un <br>implant autonome</h1>
                        <p>Le BioTagSensor, un implant autonome multi-capteurs, transmet en continu des données biologiques et comportementales.</p>
                    </v-col>
                </v-row>
            </div>
        </div>
        <v-row >
            <v-col cols="12" sm="0" lg="1"></v-col>
            <v-col cols="12" sm="12" lg="4" class="mt50 pd5">
                <div id="le-biotagsensor" class="trais"></div>
                <h1 class="green-title">Le BioTagSensor</h1>
                <p class="text-classi"> <br> L'implant BioTagSensor intègre une microcarte électronique programmable placée dans une capsule biocompatible, ainsi que plusieurs capteurs. Les données sont transmises en temps réel par NFC et/ou BLE vers un téléphone mobile, une tablette ou tout autre équipement compatible comme le boîtier Convergence. <br> <br>
                    L'implant BioTagSensor est une innovation majeure sur le marché des équipements médicaux connectés :  <br>
                    • Fiabilité des mesures physiques et biologiques<br>• Agilité pour intégrer de nouveaux capteurs (implant plateforme) <br> • Taille "grain de riz" <br><br>
                    L'implant est particulièrement bien adapté pour aider au diagnostic, surveiller les paramètres de santé d’une maladie chronique par exemple ou suivre un traitement médical, aussi bien pour les adultes que pour les enfants.
                </p>
                <router-link to="/contact" id="btn" class="btn-contact" >Contactez-nous</router-link>
            </v-col>
            <v-col cols="12" sm="1" lg="2"></v-col>
            <v-col cols="12" sm="10" lg="4">
                <div class="img-doigt">
                    <img src="../images/doigt.png" alt="cage équipée">
                </div>
            </v-col>
        </v-row>
        <v-row >
            <v-col cols="12" sm="0" lg="1"></v-col>
             <v-col cols="12" sm="12" lg="10" class="row-between mt50">
                    <div class="colonnes">
                        <div class="data-mesurées" style="background-color: #D0D4D9;">
                            <h3>Mesures</h3>
                            <div>
                                <div class="trais-v"></div>
                            </div>
                            <ul>
                                <li style="display: flex; align-items: center;"> 
                                    <img src="../assets/ico/temp.png" alt="" >
                                    Température
                                </li>
                                <li style="display: flex; align-items: center;">
                                    <img src="../assets/ico/accel.png" alt="" >
                                    Accélération 3D
                                </li>
                                <li style="display: flex; align-items: center;">
                                    <img src="../assets/ico/ppg.png" alt="" >
                                    PPG
                                </li>
                                
                            </ul>
                        </div>
                        <div class="data-mesurées" >
                            <h3>Données</h3>
                            <div>
                                <div class="trais-v"></div>
                            </div>
                            <ul>
                                <li style="display: flex; align-items: center;">
                                    <img src="../assets/ico/coeur.png" alt="">
                                        Rythme cardiaque
                                </li>
                                <li style="display: flex; align-items: center;">
                                    <img src="../assets/ico/o2.png" alt="" >
                                    Taux d'oxygène
                                </li>
                                <li style="display: flex; align-items: center;">
                                    <img src="../assets/ico/pa.png" alt="" >
                                        Tension artérielle
                                </li>
                                <li style="display: flex; align-items: center;">
                                    <img src="../assets/ico/alerte.png" alt="" >
                                    Alerte intégrée
                                </li>
                                <li style="display: flex; align-items: center;">
                                    <img src="../assets/ico/ph.png" alt="" >
                                        pH
                                </li>
                                <li style="display: flex; align-items: center;">
                                    <img src="../assets/ico/mouv.png" alt="" >
                                    Activité & Comportement
                                </li>
                                <li style="display: flex; align-items: center;">
                                    <img src="../assets/ico/pas.png" alt="" >
                                    Compteur de pas
                                </li>
                                <li style="display: flex; align-items: center;">
                                    <img src="../assets/ico/stress.png" alt="" >
                                    Stress
                                </li>
                                <li style="display: flex; align-items: center;">
                                    <img src="../assets/ico/sommeil.png" alt="" >
                                    Sommeil
                                </li>
                            </ul>
                        </div>
                    
                    <div class="avantages">
                        <h3>avantages</h3>
                        <div class="row-center">
                            <div class="trais-v"></div>
                        </div>
                        <div class="text-avantage" style="margin-top: 35px;">
                            <div>
                                <img src="../assets/ico/flèche.png" alt="" style="width: 5%">
                                <h4>Implant</h4>
                            </div>
                        </div>
                        <div class="text-avantage">
                            <ul style="text-align: left; width: 65%;">
                                <li>	Mesures Santé très fiables </li>
                                <li>    Suivi Santé continu</li>
                                <li>	Miniature, ultra résistant et sécurisé</li>
                                <li>	Autonome avec Alerte intégrée</li>
                            </ul>
                        </div>
                        <div class="text-avantage" style="margin-top: 30px;">
                            <div>
                                <img src="../assets/ico/flèche.png" alt="" style="width: 5%">
                                <h4>Patients</h4>
                            </div>
                        </div>
                        <div class="text-avantage">
                            <ul style="text-align: left; width: 65%;">
                                <li> Suivi sans contrainte</li>
                                <li>	Accessible à toutes personnes</li>
                                <li>	Amélioration de la prise en charge</li>
                                <li>	Aucune précaution particulière quotidienne</li>
                            </ul>
                        </div>
                    </div>
                    </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="1" lg="1"></v-col>
            <v-col cols="12" sm="10" lg="10" >
                <div class="trais-vr"></div>
                <h1 class="green-title mt50">Comment ça marche ?</h1>
                <div class="fonctionnement">
                    <div>
                        <img src="../images/implant_fonctionnement.png" alt="médecin">
                        <p class="text-classi">1-Kit BioTagSensor <br>
                        </p>
                    </div>
                    <div>
                        <img src="../images/implantation.jpg" alt="sportif">
                        <p class="text-classi">2-Implantation par un docteur, un infirmier..</p>
                    </div>
                    <div>
                        <img src="../images/montre_connectée.jpg" alt="écoliers">
                        <p class="text-classi">3-Associez votre implant avec BioTag App.</p>
                    </div>
                    <div>
                        <img src="../images/medecin_courbes.jpg" alt="médecin">
                        <p class="text-classi">4-Suivez vos données sur Biotag Cloud</p>
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="1"></v-col>
            <v-col cols="12" sm="10" >
                <h1 class="green-title">Nos offres et services</h1>
                <div class="offres">
                    <div>
                        <img src="../images/medecin_explique.jpg"  alt="médecin" class="effect">
                        <h3>Medical</h3>
                        <p class="text-classi">Un niveau médical atteint par des mesures sous-cutanées très précises car non perturbées par les graisses, l’eau, la sueur, les poils. <br><br><br></p>
                        <router-link to="/biotag/medical" href="#top" id="btn-2">en savoir plus</router-link>
                    </div>
                    <div>
                        <img src="../images/courreur.jpg" alt="sportif" class="effect">
                        <h3>Performance</h3>
                        <p class="text-classi" >Un suivi ultra précis de vos performances sans aucune contraintes quelques soient les environnements (sport, feu …) et les situations même à risques (militaire).<br><br></p>
                        <router-link to="/biotag/performance" id="btn-2">en savoir plus</router-link>
                    </div>
                    <div>
                        <img src="../images/travail_collaboratif.jpg" alt="travail" class="effect">
                        <h3>Plateforme</h3>
                        <p class="text-classi" >Notre savoir-faire ouvert à nos partenaires et clients pour l’étude et l’intégration de leurs capteurs ou solutions.<br><br><br><br> </p>
                        <router-link to="/biotag/plateforme" id="btn-2">en savoir plus</router-link>
                    </div>
                    <div>
                        <img src="../images/ecoliers.png" alt="écoliers" class="effect">
                        <h3 >Convergence</h3>
                        <p class="text-classi" >Un système global agile, en remplacement d’un téléphone ou d’une tablette, capable d’offrir le suivi individuel d’un implant (Ex. un enfant) jusqu’au suivi d’un groupe (hôpital, sportif, bateau).</p>
                        <router-link to="/boitier/convergence" id="btn-2">en savoir plus</router-link>
                    </div>
                    <div >
                        <img src="../images/alerte.jpg" alt="" class="effect" >
                        <h3>Biotag Cloud</h3>
                        <p class="text-classi">Une plateforme sécurisée de gestion de données en temps réel et historiques avec une grande capacité de stockage <br><br><br><br></p>
                        <router-link to="/logiciel/health-cloud" id="btn-2">en savoir plus</router-link>
                    </div>
                </div>
            </v-col>
        </v-row>
        <foot/>
    </div>
</template>
<script>
import foot from '@/components/Footer.vue'
export default {
    components:{
      foot
    },
}
</script>

<style scoped>

.img-home{
    background-size: cover;
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    text-align: left;
    position: relative;
}

#img-home{
    background-image: url(../images/background_landing_page.jpg);
}

.img-home::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* Ajout du filtre opaque noir */
    background-color: rgba(0, 55, 109, 0.33);
}

.text-home {
    position: relative;
    top: 150px;
  }

.text-home h1{
    width: 70%;
    margin-bottom: 20px;
    margin-left: 5%;
}

.text-home div{
    width: 100%;
    background-color: white;
    margin-bottom: 40px;
}

.text-home p{
    font-family: 'Roboto', sans-serif;
    font-size: 25px;
    font-weight: 500;
    color: white;
    width: 55%;
    margin-bottom: 40px;
    margin-left: 5%;
}

.text-avantage{
    display: flex;
    justify-content: space-around;
}

.text-avantage div{
    display: flex; 
    align-items: left; 
    text-align: left;
    width: 85%;   
}

.text-avantage li{
    margin-top: 10px; 
    font-family: 'roboto' sans-serif;
    font-size: 1rem;
}

.img-doigt{
    margin-top: 40px;
}

.img-doigt img{
    width: 105%;
}

.btn-contact{
    display: none;
}

.offres{
    display: flex;
    justify-content: space-around;
    margin-bottom: 50px;
}

.offres div{
    width: 18%;
    margin-top: 50px;
    background-color: white;
    padding-bottom: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    max-width: 100%; 
    overflow: hidden;
}

.offres div h3{
    font-family: 'roboto' sans-serif;
    font-size: 20px;
    color: var(--vertfonce);
    margin-top: 25px;
    margin-left: 10px;
}

.fonctionnement{
    display: flex;
    justify-content: space-around;
}

.fonctionnement div{
    width: 23%; 
    text-align: left;
    margin-top: 50px;
    text-align: center;
    padding-bottom: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    max-width: 100%; 
    overflow: hidden;
}

.fonctionnement div img{
    width: 100%;
    border: solid 1px var(--bleu);
    transition: transform 0.3s ease-in-out; /* ajouter une transition fluide */
}

.fonctionnement div img:hover{
    transform: scale(1.2); /* agrandir l'image de 20% */
}

.avantages{
    width: 30%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    background-color: white;
    border-radius: 20px;
    text-align: center;
    border-radius: 20px;
}

.avantages h3{
    color: var(--violet);
    font-family: 'roboto' sans-serif;
    font-size: 25px;
    margin-bottom: 20px;
    margin-top: 20px;
    text-transform: uppercase;
    font-weight: 600;
}

.avantages li{
    text-align: justify;
}

.effect{
    width: 100%;
    border: solid 1px var(--bleu);
    transition: transform 0.3s ease-in-out; /* ajouter une transition fluide */
}

.effect:hover{
    transform: scale(1.2); /* agrandir l'image de 20% */
}

.offres div p{
    margin-top: 16px;
    margin-bottom: 50px;
    margin-left: 10px;
    margin-right: 10px;
}

.offres div a{
    right: -5%;
}

@media (max-width: 1264px) {
.img-home{
    margin-top: 130px;
    height: 80vh;
}

.img-doigt img{
    width: 100%;
}

.text-home{
    text-align: justify;
    margin-top: 50px;
}

.text-home h1{
    width: 100%;
    margin-bottom: 10px;
}



.offres div{
    width: 45%;
    margin-left: 5.5%;
}
.fonctionnement{
    display: block;
}

.fonctionnement div{
    width: 100%;
}

.data-mesurées{
    margin-left: 5%;
    width: 90%;
    background-color: #D0D4D9;
}
}

@media (max-width: 765px) {
.img-doigt img{
    width: 100%;
}
.offres{
    display: block;
}

.offres div{
    width: 90%;
}
}
</style>
